<template>
  <div class="footer">
    <p>All rights reserved &copy; {{new Date().getFullYear()}}</p>
  </div>
</template>

<script>
  export default{
    name: 'Footer'
  }
</script>
<style lang="scss" scoped>
.footer{
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  border-top: var(--primary-color) 5px solid;
  padding: 30px 0;
  font-size: 16px;
  color: var(--primary-color);
  margin-bottom: 0;
  text-transform: uppercase;
}
</style>
