<template>
  <Header/>
  <router-view></router-view>
  <Footer/>
</template>
<script>
  import store from '@/store'
  import './assets/css/app.scss';
  import './assets/js/main.js';
  import Header from './components/Header.vue'
  import Footer from './components/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    },
    provide: {store}
  }
</script>
