<template>
  <div class="smartphone-wrapper">
    <div class="smartphone">
      <div class="iframe-wrapper">
        <iframe src="/demo" style="width:100%;border:none;height:100%" ></iframe>
      </div>
    </div>
  </div>
  <div class="featured">

    <h1 class="title">WooApp - The only E-Commerce App you will ever need!</h1>

    <p>
    WooApp is a hybrid mobile app solution wrapped in Phonegap/Cordova with 100% native experience, it will provide you with a mobile application for your Woocommerce website with ultimate features and friendly UI!

    it is very easy to install, Just install the WooApp plugin and your ready to go, also it can be deployedto any platform (IOS - Android .. etc) with no effort!

    Increase your sales by providing a powerful app to your customers, Purchase now and get your new mobile app ready for your customers in less than half an hour!
    </p>
  </div>
  <div class="container" id="features">
    <div>
      <div class="group">
        <h2>Overview:</h2>

        <ul>
         <li>Compatible with latest Wordpress and Woocommerce.</li>

         <li>Fully responsive and compatible with Android - Iphone - Ipads and all tablets.</li>

         <li>Fully compatible with IPhone x.</li>

         <li>Plug it with your current Woocommerce website and you are ready to go!</li>

         <li>No need for coding skills.</li>

         <li>No need for Woocommerce API to be enabled.</li>

         <li>Not dependant on any other plugin.</li>

         <li>Just upload the Sama Ultimate Wordpress Plugin and you are good to go!</li>

         <li>Very easy setup and customization!</li>
        </ul>
      </div>

      <div class="group">
        <h2>Ultimate Features:</h2>
          <ul>
         <li>User Wishlist.</li>

         <li>Product Labels (Sales - New arrivals - Custom labels).</li>

         <li>Product Compare.</li>

         <li>Supports WPML Multilanguage support!</li>

         <li>Home page banner slider</li>

         <li>Home page products slider</li>

         <li>Search functionality</li>

         <li>Product Sort & Filters!</li>

         <li>Supports woocommerce shipping/fees/tax calculation</li>

         <li>Supports simple and variable products</li>

         <li>Rating & Reviews</li>

         <li>Product Image Gallery</li>

         <li>Select Address location on Map on checkout</li>

         <li>User Profile and manage addresses</li>

         <li>App Menu is added and managed directly from Wordpress native menus!</li>

         <li>Cart Page</li>

         <li>Support Coupons</li>

         <li>Track Order </li>

         <li>User Orders and Order page</li>

         <li>User Login and Registration</li>

         <li>Supports Cash on delivery orders, can be integrated with payments gateways.</li>

         <li>And much more features!</li>
        </ul>
      </div>

      <div class="group">
        <h2>Payment Gateways:</h2>
        <p>All major payment gatways are supported by default, No need for extra configuration! Also we will develop for you any extra payment gateway without any extra charge! Currently supported gateways:</p>
        <ul>
          <li>Paypal</li>
          <li>Stripe</li>
          <li>Payfort</li>
          <li>Payfort Installments</li>
          <li>KNET Pay/ KNET CC</li>
          <li>Cash on delivery</li>
          <li>We are happy to integrate any other payment gateway upon request!</li>
        </ul>
      </div>

      <div class="group">
        <h2>Support:</h2>
        <p>Our team will provide you best support pre and after installation to make sure your integration process goes through smoothly.</p>
        <ul>
          <li>24/24 Chat support</li>
          <li>Any bugs and feature requests can be posted to the <a target="_blank" href="https://wooapp.peerboard.com/">Community Forum</a></li>
          <li>We have a very straight forward <a href="/docs">documentation</a></li>
        </ul>
      </div>

      <div class="group">
        <h2>Customization</h2>
        <p>Our team is ready for any kind of support! We are open to customization at very low rates, Also if you don't have any running web store we can build your platform from scratch and help you publish the Apps to the stores!</p>
      </div>

      <div class="group">
        <h2>Credits:</h2>
          <ul>
         <li><a href="https://framework7.io/" target="_blank">Framework7</a></li>

         <li><a href="https://jquery.com/" target="_blank">JQuery</a></li>

         <li><a href="https://cordova.apache.org/" target="_blank">Cordova</a></li>
        </ul>
      </div>

      <div class="buttons">
        <a class="play" target="_blank" href="https://play.google.com/store/apps/details?id=com.tqniyati.samaultimate"><img src="../assets/images/play.png"/></a>
        <PurchaseButton/>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PurchaseButton from '@/components/buttons/PurchaseButton.vue'

export default {
  name: 'Home',
  components: {
    PurchaseButton
  },
}
</script>
<style lang="scss" scoped>
.smartphone-wrapper{
  background: url("../assets/images/bg.png");
  background-size: cover;
  padding: 50px 0;
  @include sm{
    padding: 20px 0;
  }
  .smartphone {
    flex-shrink: 0;
    box-shadow: 0 0 20px 10px rgba(0,0,0,.2);
    width: 385px;
    height: 650px;
    padding: 5px;
    border-radius: 25px;
    background: #000;
    align-self: center;
    margin-left: 60px;
    position: relative;
    margin-right: -10px;
    box-sizing: border-box;
    margin: 0 auto;
    @include sm{
      margin: 0 auto;
      width: 95%;
      height: 550px;
    }
    .iframe-wrapper{
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      height: 100%;
      iframe{
        height: 100%;
        display: block;
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}
.featured{
  background: #212121;
  color: #FFF;
  padding: 100px;
  text-align: center;
  @include sm{
    padding: 60px 30px;
  }
  >h1{
    margin-bottom: 20px;
  }
  h1,p{
    transition: var(--transition-speed);
  }
}
.featured:hover{
  h1,p{
    transform: scale(1.1);
  }
}
.container{
  >div{
    width: 1100px;
    max-width: 100%;
    margin: auto;
    padding: 60px 0;
    @include md{
      padding: 20px 0;
    }
    >.group{
      margin: 25px 20px;
      margin-bottom: 30px;
      border-radius: 20px;
      padding: 35px 35px;;
      box-shadow: 1px 1px 20px rgba(0,0,0,0.2);
      transition: var(--transition-speed);
      @include sm{
        margin: 25px 10px;
      }
      >h2{
        margin-bottom: 10px;
      }
      ul{
        padding-left: 40px;
        margin: 15px 0;
        @include sm{
          padding-left: 15px;
        }
      }
      li{
        list-style: disc;
      }
    }
    >.group:hover{
      transform: scale(1.01) rotate(1deg);
    }
    >.group:nth-child(odd):hover{
      transform: scale(1.01) rotate(-1deg);
    }
  }
}
.buttons{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
  @include sm{
    flex-wrap: wrap;
  }

  a{
    width: 250px;
    margin: 0 30px !important;
    transition: var(--transition-speed);
    @include sm{
      width: 70%;
    }
    img{
      width: 100%;
      display: block;
    }
  }
  a:hover{
    transform: scale(1.1);
  }
  .purchase-now{
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 10px;
    font-size: 20px;
    @include sm{
      margin-top: 20px !important;
      height: 80px;
    }
  }
  .purchase-now:hover{
    border: solid thin var(--primary-color);
  }
}
</style>
