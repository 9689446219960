<template>
  <a class="purchase-now" target="_blank"  href="https://codecanyon.net/item/sama-ultimate-complete-woocommerce-mobile-application/22811820">Purchase Now</a>
</template>
<script>
  export default{
    name: "PurchaseButton"
  }
</script>
<style lang="scss" scoped>
.purchase-now{
  background: var(--purchase-background);
  color: var(--purchase-text);
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--header-font);
  padding: 5px 25px;
  border-radius: 20px;
  transition: var(--transition-speed);
  font-size: 15px;
}
.purchase-now:hover{
  background: #FFF;
  color: var(--primary-color);
  transform: scale(1.02);
}
</style>
