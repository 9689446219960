<template>
  <transition name="fade">
    <div class="menu" v-if="store.state.menuVisible">
      <button class="close" @click="store.state.menuVisible = !store.state.menuVisible"><i class="fa fa-times"></i></button>
      <ul class="list">
        <li><a target="_blank" href="https://wooapp.peerboard.com/">Forum</a></li>
        <li><a target="_blank" href="/docs">Docs</a></li>
        <li><a  href="#features" v-smooth-scroll @click="hideMenu">Features</a></li>
      </ul>
    </div>
  </transition>
</template>
<script>

  export default{
    inject: ["store"],
    methods:{
      hideMenu(){
        this.store.state.menuVisible = false;
        this.$smoothScroll({
          scrollTo: document.getElementById('features'), // scrollTo is also allowed to be number
          hash: '' // required if updateHistory is true
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .menu{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--menu-background);
    z-index: 999;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .close{
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
    color: #FFF;
    font-size: 30px;
  }
  .list{
    color: #FFF;
    margin-top: 100px;
    text-align: center;
    font-size: 30px;
    li{
      margin-bottom: 40px;
      color: #FFF;
      a{
        color: #FFF;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 3px;
      }
    }
  }
</style>
