<template>

  <div class="header">
    <a href="#" @click="store.state.menuVisible = !store.state.menuVisible" class="open-menu"><i class="fa fa-bars"></i></a>
    <PurchaseButton/>
    <router-link to="/" class="logo"><img src="../assets/images/logo.svg"/></router-link>
    <ul class="menu">
      <li><a target="_blank" href="https://wooapp.peerboard.com/">Forum</a></li>
      <li><a target="_blank" href="/docs">Docs</a></li>
      <li><a  href="#features" v-smooth-scroll>Features</a></li>
    </ul>
  </div>
  <Menu />
</template>

<script>

import PurchaseButton from '@/components/buttons/PurchaseButton.vue'
import Menu from '@/components/Menu.vue'
export default{
  inject: ["store"],
  name: 'Header',
  components: {
    PurchaseButton,
    Menu
  },
}
</script>

<style lang="scss" scoped>
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  z-index: 1;
  font-size: 16px;
  background: var(--header-background);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 10px rgba(0,0,0,.6);
  .purchase-now{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0,-50%);
    @include md{
      right: 10px;
      font-size: 14px;
    }
  }
  .purchase-now:hover{
    transform: scale(1.02) translate(0,-50%);
  }
  .menu{
    display: flex;
    margin: 0;
    list-style: none;
    position: absolute;
    left: 30px;
    padding: 0;
    color: #FFF;
    @include md{
      display: none;
    }
    li{
      margin: 0 20px;
      transition: var(--transition-speed);
    }
    li:hover{
      transform: scale(1.2);
    }
    a{
      color: #FFF;
      text-decoration: none;
      text-transform: uppercase;

    }
    a:hover{

    }
  }
  .logo{
    height: 60%;
    display: block;
    @include md{
      display: none;
    }
    img{
      display: block;
      height: 100%;
    }
  }
  .open-menu{
    display: none;
    @include md{
      display: block;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 30px;

    }
  }
}
</style>
